import Icon, { IconsNames } from '@/components/v3/Icon';
import { LinkWrapper } from './LinkWrapper';

export interface CardProps {
  icon: IconsNames;
  title: string;
  description: string;
  linkHref?: string;
}

export function Card(props: CardProps) {
  const { description, icon, title } = props;

  return (
    <div className="flex lg:flex-col p-16 lg:p-24 w-full rounded-md lg:rounded-2xl border-2 border-display-200 hover:border-stone-200 cursor-pointer">
      <LinkWrapper {...props}>
        <div className="">
          <div className="flex flex-row lg:flex-col">
            <div className="mr-16 mb-16 min-w-max">
              <Icon name={icon} className="w-32 h-32" />
            </div>
            <div>
              <p className="font-display text-lg lg:text-xl font-medium text-stone-600">
                {title}
              </p>

              <p className="pt-8 text-base lg:text-lg text-display-900">
                {description}
              </p>
            </div>
          </div>
        </div>
      </LinkWrapper>
    </div>
  );
}
