import Image from 'next/image';

import OnDesktop from '@/components/MediaQueries/OnDesktop';
import OnMobile from '@/components/MediaQueries/OnMobile';
import OnTablet from '@/components/MediaQueries/OnTablet';
import Button, { ButtonProps } from '../../base/ui/Button';
import { ImageResponsiveCMSProps } from '@/types/cms';

interface Props {
  referId: string;
  title: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageResponsiveCMSProps;
  tag?: string;
}

export function FeaturedImage({
  referId,
  tag,
  title,
  description,
  image,
  buttons,
}: Props) {
  return (
    <section
      id={referId}
      className="flex relative justify-center mt-40 lg:mt-[120px] min-h-[668px] md:min-h-[588px] lg:min-h-[458px] xl:min-h-[558px] bg-display-100"
    >
      <div className="flex absolute top-[-48px] md:top-[-40px] lg:top-[-128px] xl:top-[-80px] flex-col lg:flex-row-reverse justify-center items-center px-24 m-auto lg:w-full max-w-7xl">
        <OnTablet>
          <Image
            src={image.tablet.path}
            alt={image.tablet.alternativeText}
            width={600}
            height={350}
            quality={image.tabletQuality || 70}
            style={{ objectFit: 'contain' }}
          />
        </OnTablet>

        <OnDesktop>
          <Image
            src={image.web.path}
            alt={image.web.alternativeText}
            width={791}
            height={558}
            quality={image.webQuality || 70}
            style={{ objectFit: 'contain' }}
            className="w-[600px] xl:w-[791px]"
          />
        </OnDesktop>

        <OnMobile>
          <Image
            src={image.mobile.path}
            alt={image.mobile.alternativeText}
            width={320}
            height={398}
            quality={image.mobileQuality || 70}
            style={{ objectFit: 'contain' }}
          />
        </OnMobile>

        <div className="flex flex-col justify-center items-start pt-32 md:pt-24 lg:pt-[160px] lg:mr-16 md:min-w-[404px] lg:min-w-[304px] max-w-[312px] md:max-w-[472px] lg:max-w-[404px] lg:min-h-[558px]">
          {tag && (
            <p
              className="mt-8 w-full font-display text-sm lg:text-base font-semibold text-center lg:text-left text-display-600"
              dangerouslySetInnerHTML={{ __html: tag }}
            />
          )}

          <h2
            className="w-full font-display text-5xl lg:text-7xl font-black text-center lg:text-left text-display-900"
            dangerouslySetInnerHTML={{ __html: title }}
          />

          <p
            dangerouslySetInnerHTML={{ __html: description }}
            className="mt-8 mb-16 w-full md:text-base lg:text-lg text-center lg:text-left text-display-900"
          />

          <div className="flex gap-16 justify-center lg:justify-start w-full">
            {buttons?.map(button => (
              <Button key={button.label} {...button} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
